//*// VIEWS //*//

//standard view
//table view inherited form tables & tumbnail view on thumbs.plone.less
.folder-listing {
	font-weight: @plone-font-weight-bold;
	list-style: none;
	position: relative;
	padding-left: 20px;
	//need to add a list portlets-like bullet
	li {margin: 10px 0;}
	> li:before {
			content:"•"; position: absolute; font-size: calc(~"1.2vw + 10px"); margin-top: -9px; left: 0;
			color: lighten(@plone-portlet-list-bullet, 15%);
	}
	.documentByLine {font-weight: @plone-font-weight-regular; color: @plone-gray-light
	}
}

//summary view
.tileImage { padding-right: 1.2em; float:left; }
.tileImageNoFloat { margin-bottom: 5px; }
.tileFooter { clear: both; margin-top: 5px; font-size:1.5vw }

// clear floats:
.entries::before,
#content article.entry::after,
#relatedItemBox ul li::after, 
#searchresults ul li::after  {
  content: " ";
  display: block;
  clear: both;
  height:2px;  //allow minimal vertical space for thumbs
  visibility: hidden;
}
//structure pattern, fix thumb positioning in foldercontents
.icon-group-right .image-icon {
  float:none;
}
