//*// TOOLTIP //*//

.tooltips {
	opacity: 0;
	transition: opacity .2s ease .5s;
	position: absolute;
	background: #eee;
	//background: rgba(0,0,0,.9);
	color: black;
	font-size: calc(~"1.0vw + 10px");
	border-radius: @plone-border-radius-base;
	box-shadow: 0 2px 6px rgba(0,0,0,.17);
	max-width: 200px;
	margin-left: -100px;
	left: 200px;
	text-align: center;
	padding: @plone-padding-base-vertical;
	&.active {
		opacity: 1;
		transition: opacity .2s ease .5s;
	}
}