//*// HEADER //*//
#portal-header {
    margin: 0 0 0 0;
    .clearfix(); //clear floats
}
#portal-logo {
    float: left;
}
#portal-languageselector {
    //clear: both;
  position: absolute;
  top:5%;
  right:1%; 
  z-index: 110;   
  display: inline-block;
  font-size:calc(~"1vw + 10px");
    li {
        display: inline-block;
        //margin-right: 10px;
    }
}
#portal-anontools {
  position:absolute;
  top: 1%;
  float:left;
  right: 1%;
  z-index: 111;
  font-size:calc(~"1vw + 10px");
	ul {
		padding-right: 0;
	}
}
#portal-membertools-wrapper {
    position:absolute;
    z-index:111;
    top: 1%;
    right:1%;
    float: left;
    font-size:125%;
    ul {
       //margin: 0 0 @plone-padding-base-horizontal @plone-padding-base-horizontal;
       padding-right: 0;
     }
 }
     
#personaltools-login, #personaltools-join {
  color:white;
  &:hover{
    color:@fgr-hover;
    text-decoration:none;
  }
}
//mobile search
#portal-searchbox {
    clear: left;
    margin-left: 3px;
    margin-top: 60px;
    padding-top: 20px;
    position: absolute;
    white-space: nowrap;
    width: 67%;
    z-index: 2;
    .searchSection {display: none;} //non on mobile
    [type="submit"] {display: none;} //submit hidden on mobile
    label {font-size: 86.667%}
}
#searchbox_currentfolder_only {
    vertical-align: middle;
}

//non mobile search
@media (min-width: @plone-grid-float-breakpoint) {
    #portal-header {
        margin-bottom: 0;
    }
	#portal-languageselector {
        clear: none;
        float: right;
	}
    #portal-searchbox {
        clear: none;
        float: right;
        margin-left: 1em;
        margin-top: 5px; //visual balance
        max-width: 50%;
        padding-top: 0;
        position: relative;
        width: 250px; 
        .searchSection {display: inherit;} //yes for non mobile
        [type="text"] {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            float: left;
            width: 20rem;
        }
        [type="submit"] {
            display: inline-block;
            margin-left: -1px;
            vertical-align: top;
            width: auto;
        }
    }
}