//*// SITEMAP //*//

#portal-sitemap {
	list-style: none;
	padding-left:0;
	> li {
		font-size: calc(~"1.2vw + 5px");
		border-top: @plone-portlet-border;
		padding: @plone-padding-base-vertical 0;
	}
	ul {
		list-style: none;
		position: relative;
		font-size: calc(~"1.vw + 8px");
		padding-left:20px;
	    & li {
			position: relative;
		}
	    
	}
}
