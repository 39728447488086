//
@media screen {
/* The 16-column Deco Grid System for fixed width of 1024px:
 * Cells are 28.0px (2.734375%), margin will be 36px (3.515625%).
 * Portal left/right margin is 18.0dpx (1.7578125%).
 */


div.cell {
  position: relative;
  float: left;
  left: 100%;
}

/* Width classes */

div.width-1 { width: 2.7344%; }
div.width-2 { width: 8.9844%; }
div.width-3 { width: 15.2344%; }
div.width-4 { width: 21.4844%; }
div.width-5 { width: 27.7344%; }
div.width-6 { width: 33.9844%; }
div.width-7 { width: 40.2344%; }
div.width-8 { width: 46.4844%; }
div.width-9 { width: 52.7344%; }
div.width-10 { width: 58.9844%; }
div.width-11 { width: 65.2344%; }
div.width-12 { width: 71.4844%; }
div.width-13 { width: 77.7344%; }
div.width-14 { width: 83.9844%; }
div.width-15 { width: 90.2344%; }
div.width-16 { width: 96.4844%; }

/* Positioning classes, these are subtracting from a rightmost position,
 * which is why they seem the wrong way around */

div.position-0 { margin-left: -100%; }
div.position-1 { margin-left: -91.9922%; }
div.position-2 { margin-left: -85.7422%; }
div.position-3 { margin-left: -79.4922%; }
div.position-4 { margin-left: -73.2422%; }
div.position-5 { margin-left: -66.9922%; }
div.position-6 { margin-left: -60.7422%; }
div.position-7 { margin-left: -54.4922%; }
div.position-8 { margin-left: -48.2422%; }
div.position-9 { margin-left: -41.9922%; }
div.position-10 { margin-left: -35.7422%; }
div.position-11 { margin-left: -29.4922%; }
div.position-12 { margin-left: -23.2422%; }
div.position-13 { margin-left: -16.9922%; }
div.position-14 { margin-left: -10.7422%; }
div.position-15 { margin-left: -4.4922%; }

/* Convenience classes. Not strictly necessary. */
div.width-full { width: 100% ; box-sizing: content-box; }

div.width-1\3a 2 { width: 46.4844%  ; box-sizing: content-box; }
div.position-1\3a 2 { margin-left: -48.2422% ; box-sizing: content-box; }

div.width-1\3a 3 { width: 29.8177%; box-sizing: content-box; }
div.width-2\3a 3 { width: 63.1510% ; box-sizing: content-box; }
div.position-1\3a 3 { margin-left: -64.9089% ; box-sizing: content-box; }
div.position-2\3a 3 { margin-left: -31.5755% ; box-sizing: content-box; }

div.width-1\3a 4 { width: 21.4844% ; box-sizing: content-box; }
div.width-2\3a 4 { width: 46.4844% ; box-sizing: content-box; }
div.width-3\3a 4 { width: 71.4844% ; box-sizing: content-box; }
div.position-1\3a 4 { margin-left: -73.2422% ; box-sizing: content-box; }
div.position-2\3a 4 { margin-left: -48.2422% ; box-sizing: content-box; }
div.position-3\3a 4 { margin-left: -23.2422% ; box-sizing: content-box; }

div.width-1\3a 5 { width: 16.4844% ; box-sizing: content-box; }
div.width-2\3a 5 { width: 36.4844% ; box-sizing: content-box; }
div.width-3\3a 5 { width: 56.4844% ; box-sizing: content-box; }
div.width-4\3a 5 { width: 76.4844% ; box-sizing: content-box; }
div.position-1\3a 5 { margin-left: -78.2422% ; box-sizing: content-box; }
div.position-2\3a 5 { margin-left: -58.2422% ; box-sizing: content-box; }
div.position-3\3a 5 { margin-left: -38.2422% ; box-sizing: content-box; }
div.position-4\3a 5 { margin-left: -18.2422% ; box-sizing: content-box; }

div.width-1\3a 6 { width: 13.1510% ; box-sizing: content-box; }
div.width-2\3a 6 { width: 29.8177% ; box-sizing: content-box; }
div.width-3\3a 6 { width: 46.4844% ; box-sizing: content-box; }
div.width-4\3a 6 { width: 63.1510% ; box-sizing: content-box; }
div.width-5\3a 6 { width: 79.8177% ; box-sizing: content-box; }
div.position-1\3a 6 { margin-left: -81.5755% ; box-sizing: content-box; }
div.position-2\3a 6 { margin-left: -64.9089% ; box-sizing: content-box; }
div.position-3\3a 6 { margin-left: -48.2422% ; box-sizing: content-box; }
div.position-4\3a 6 { margin-left: -31.5755% ; box-sizing: content-box; }
div.position-5\3a 6 { margin-left: -14.9089% ; box-sizing: content-box; }
}



.managePortletsAboveLink, 
.managePortletsBelowLink,
.manageFooterPortletsLink,
.manageInHeaderPortletsLink,
.managePortletsBelowTitleLink { text-align: center; margin: 0 auto 1em; clear:both;}

.portlet-manager-row .portlet-well_manager { width: 30%; float:left; margin:1%; }
.portlet-manager-row { width:100%; float:left; }

#content-header,
#content-header .container,
#content-header .container .row
{
  width:100% !important!;
  min-width: 100% !important;
  //heigth:100% !important!;
  //min-heigth: 100% !important;
  padding:0 !important;
  margin:0 !important;
}

#content-header video,
#content-header img
 {
    object-fit: cover !important;
}  